import React, { useEffect, useState } from 'react';
import {Row, Col,Card } from 'reactstrap';
import './proposal.scss';

const Proposal = ({camData,loanId,metaData}) => {
    const defaultGst = camData.gst_profile_details && Object.keys(camData.gst_profile_details);
    const [gst,setGst] = useState('');

    useEffect(()=>{

    var gstnull = null;
    var gstflag = false;
    
    camData.gst_profile_details && ( Object.keys(camData.gst_profile_details).map((key,i) => {        
        
        if(gstnull === null) {
            gstnull = defaultGst[i];
        }

        if(camData.gst_profile_details[defaultGst[i]].profile.is_primary === true){            
            setGst(defaultGst[i]);
            gstflag = true;
        }    
    }))   

    if(gstflag === false) {
        setGst(gstnull);
    }  

    },[defaultGst]);

  const toCapitaliseWord = (str) => {
    const words = str.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] =  words[i][0] ? words[i][0].toUpperCase() + words[i].substr(1) : '';
    }
    const text = words.join(" ");
    return text;
  };

  const evaluator = (x) => {
      let y = "";
      if(x >=0 && x !== null && x !== ""){
          y = "₹ " + Number(x).toLocaleString("en-IN")
      }
      else {
          y ="-"
      }
      return y;
  }

  function numberWithCommas(x) {
    x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return "₹ " + x
  }
    return (
        <Col md="6" className="proposal p-3 pb-4 col-mds-12">
            {camData &&
            <Card className="bg-white proposal_card" >
                
                    <Row>
                        <Col md="12" >
                            <div className="float-left d-flex ">
                                <img className="pr-2" src="/images/cam_version_3/proposal_ican.svg" alt="proposal ican" />
                                <p className="pt-2 pHead">Proposal</p>
                            </div>
                            <div className="float-right d-flex">
                                <div className="program-text">
                                    {metaData && metaData.program_details && metaData.program_details.name &&
                                    <div className="pb-1">Program Name : {metaData && metaData.program_details && metaData.program_details.name}</div>
                                    }
                                    {metaData && metaData.program_details && metaData.program_details.program_band &&
                                    <div>Program Band : {metaData && metaData.program_details && metaData.program_details.program_band}</div>
                                    }

                                </div>
                                <div className="card-text">ID - {loanId}</div>
                            </div>
                        </Col>

                    </Row>
                    <hr className="my-2" />
                    
                    <Row>
                        <Col md="4" className="pr-1 print-col" >
                            <div className="d-flex userBox print-margin">
                                <img className="pr-2 img-fluid" src="/images/cam_version_3/proposal_user.svg" alt="proposal user"/>
                                <p className="userFont text-capitalize">{camData && camData.co_applicants[0] && (camData.co_applicants[0]['name']).toLowerCase() || "-"}</p>
                            </div>
                        </Col>
                        <Col md="4" className="pl-1 pr-1 print-col">
                            <div className="d-flex anchorBox print-margin">{
                                (camData && camData.summary && camData.summary.anchor_logo) ?
                                <img className="mr-2 img-fluid alogo" src={camData.summary.anchor_logo} /> 
                                :
                                <p className="anchorImgLogo">{camData && (toCapitaliseWord(camData.summary.anchor_name)).charAt(0)}</p>}
                                <p className="userFont">{camData && toCapitaliseWord(camData.summary.anchor_name)}</p>
                            </div>
                        </Col>
                        <Col md="4" className="pl-1 print-col">
                            <div className="posBox print-margin">
                                <p className="py-1 smallFont">Customer</p>
                                <p className="boldFont">{camData.co_applicants[0] && camData.co_applicants[0].existing_user ? "Existing" : "New"}</p>
                            </div>
                        </Col>
                    </Row>
                   
                    <Row className="py-2">
                        <Col  className="pr-1" style={{'flex-grow' : "2"}}>
                            <div className="posBox">
                                <p className="py-1 ">Loan Amount</p>
                                <p className="boldFont  pt-1">{evaluator(camData.summary.requested_loan_amount)}</p>
                            </div>
                        </Col>
                        <Col  className="pl-1" style={{'flex-grow' : "2"}}>
                            <div className="posBox">
                                <p className="py-1 ">Total Credit Limit</p>
                                <p className="boldFont pt-1">{evaluator(camData.summary.limit_sanctioned)}</p>
                            </div>
                        </Col>
                         {/* <Col  className="pr-1 pl-1" style={{'flex-grow' : "2"}}>
                            <div className="posBox">
                                <p className="py-1 ">Total Current POS</p>
                                <p className="boldFont pt-1">{evaluator(camData.summary.current_pos_outstanding)}</p>
                            </div>
                        </Col>
                        <Col  className="pl-1" style={{'flex-grow' : "2"}}>
                            <div className="">
                                <div className="performanceBox">
                                    <span className="leftPerformance">
                                        <span className="leftPos">30+</span>
                                        <span className="rightPos">{ evaluator(camData.summary.pos_outstanding_of_30_dpd)}</span>
                                    </span>
                                    <span className="rightPerformance">
                                        <span className="leftPos">90+</span>
                                        <span className="rightPos">{evaluator(camData.summary.pos_outstanding_of_90_dpd)}</span>
                                    </span>
                                </div>
                            </div>
                        </Col> */}
                    </Row>


                    <Row className="py-2">
                        <Col md="3" className="pr-1">
                            <div className="py-1">
                                <p className="smallFont">Company</p>
                            </div>
                            <p className="cNamebox text-capitalize">{camData && camData.summary.business_entity_name ? (camData.summary.business_entity_name ).toLowerCase(): "-"}</p>
                        </Col>
                        <Col md="3" className="px-1">
                            <div className="py-1">
                                <p className="smallFont">Operate as</p>
                            </div>
                            <p className="cNamebox">{camData && camData.summary.business_entity_constitution ? camData.summary.business_entity_constitution : "-"}</p>
                        </Col>
                        <Col md="3" className="px-1">
                            <div className="py-1">
                                <p className="smallFont">Business Vintage</p>
                            </div>
                            <div className="vintagebox" style={{ backgroundColor: "#FFF4D7", color: "#ee964f" }}>
                                <img src="/images/cam_version_3/calendar_icon.svg" width="18px" alt="calendar icon"/>
                                <div>
                                    {camData.origination_score.business_vintage && camData.origination_score.business_vintage.value ? 
                                    <>
                                        <p className="d-inline vintageText">{ ((camData.origination_score.business_vintage.value)/12).toFixed(1)}</p>
                                        <sub className="vintageYr">Yrs</sub>
                                    </> : 
                                    <>-</>}
                                </div>
                            </div>
                        </Col>
                        <Col md="3" className="pl-1">
                            <div className="py-1">
                                <p className="smallFont">Anchor Vintage</p>
                            </div>
                            <div className="vintagebox" style={{ backgroundColor: "#D4F6E5", color: "#40C284" }}>
                                <img src="/images/cam_version_3/calendar_icon2.svg" width="18px" alt="calendar icon"/>
                                <div>
                                    {camData.origination_score.anchor_vintage && camData.origination_score.anchor_vintage.value ? 
                                    <>
                                        <p className="d-inline vintageText">{ ((camData.origination_score.anchor_vintage.value)/12).toFixed(1)}</p>
                                        <sub className="vintageYr">Yrs</sub>
                                    </> : 
                                    <>-</>}
                                   
                                </div>
                            </div>
                        </Col>
                    </Row>

                    
                    <Row>
                        {camData.co_applicants?.map((data,index)=>(
                        <>
                        {data.is_primary === true ? 
                        <Col md="4" className="pr-1">                             
                            <div className="py-1">
                                <p className="applicantText">Main Applicant</p>
                            </div>
                            
                            <div  className="d-flex p-2 rounded" style={{ backgroundColor: "#E8FAF1", color: "#40C284" }}>
                                <p className=" mr-auto p-2 applicantName1 text-capitalize">{(data.name).toLowerCase()}</p>
                                <p className=" p-2 rounded d-flex align-items-center" style={{ backgroundColor: "rgba(62, 214, 141, 0.12)" }} >{ data.shareholding ? data.shareholding+"%" : "N/A" }</p>
                            </div>                      
                        </Col>
                        :
                        <Col md="4" className="pr-1 coapp">                             
                            <div className="py-1">
                                <p className="applicantText">Co - Applicant 0{index}</p>
                            </div>
                            
                            <div className="d-flex p-2 rounded"  style={{ backgroundColor: "#F5F8FC", color: "#4F565E" }}>
                                <p className="mr-auto p-2 applicantName2 text-capitalize">{(data.name).toLowerCase()}</p>
                                <p className=" p-2 rounded d-flex align-items-center" style={{ backgroundColor: "rgba(79, 86, 94, 0.08)" }}  >{ data.shareholding ? data.shareholding+"%" : "N/A" }</p>
                            </div>                      
                        </Col>
                        }
                        </>
                        ))}
                    </Row>
                   
                    <hr className="my-3"/>

                    <Row>
                        <Col md="4" className="pr-1">
                            <div className="pb-1">
                                <p className="smallFont">Property Stability (In Years)</p>
                            </div>
                            
                            <div className="d-flex justify-content-between justify-content-between bg-light w-auto p-3 rounded">
                                <div >
                                    
                                        <img src="/images/cam_version_3/calendar_icon2.svg" width="13px" alt="calendar icon"/>
                                        <p className="smallFont">Business</p>
                                </div>
                                <div >
                                        <p style={{ fontSize: "14px",fontWeight: "bold", color: "#40C284" }} className="" > {camData && camData.summary.business_address && camData.summary.business_address.years_in_address ? camData.summary.business_address.years_in_address : "-"} </p>
                                </div>
                                
                               <div className="border"></div>
                                
                                    <div className=""> 
                                        <img src="/images/cam_version_3/home.svg" width="15px" alt="Home" />
                                        <p className="smallFont">Home</p>
                                    </div>
                                    <div className="">
                                        <p style={{ fontSize: "14px",fontWeight: "bold", color: "red" }} className="">{camData && camData.co_applicants[0] && camData.co_applicants[0]['property_status_residence'] ? camData.co_applicants[0]['property_status_residence'] : "-"}</p>
                                    </div>
                                
                            </div>
                        </Col>
                        <Col md="3" className="px-1">
                            <div className="d-flex py-2 pl-2 pr-0 space1 rounded" style={{ backgroundColor: "#FFFAEE"}}>
                                <div className="py-2 pr-2 pl-0">
                                    <img src="/images/cam_version_3/file_orange.svg" width="23px" alt="file_orange" />
                                </div>
                                <div className="pl-2 w-75">
                                    <p className="py-1 smallFont ">Mobile Number</p>
                                    <p className="boldFont">{camData && camData.summary.business_mobile ? camData.summary.business_mobile : "-"}</p>
                                </div>
                            </div>
                        </Col>
                        <Col md="5" className="pl-1">
                            <div className="d-flex p-2 rounded space1" style={{ backgroundColor: "#F8FDF5"}}>
                                <div className="p-1">
                                    <img src="/images/cam_version_3/file_green.svg" width="23px" />       
                                </div>
                                <div>
                                    <p className="py-1 smallFont">Email ID </p>
                                    <p className="boldFont" style={{maxWidth:"170px"}}>{camData && camData.summary.business_email ? camData.summary.business_email : "-"}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="4" className="pr-1">
                            <div className="bg-light p-2 my-3 rounded">
                                <p className="py-1 smallFont">Business PAN</p>
                                <p className="boldFont">{camData && camData.summary.business_pan ? camData.summary.business_pan :"-"}</p>
                            </div>
                        </Col>
                        <Col md="4" className="px-1">
                            <div className="bg-light p-2 my-3 rounded">
                                <p className="py-1 smallFont">GST </p>
                                <p className="boldFont">{gst ? gst : "-"}</p>
                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col md="6" className="pr-1">
                            <div className="bg-light px-2 py-3 bg-light w-100 rounded baddress">
                                <p className="py-1 smallFont">Business Address </p>
                                <p className="boldFont text-capitalize">{camData && camData.summary.business_address.address_1}</p>
                            </div>
                        </Col>
                        
                        <Col md="3" className="px-1">
                            <div className="d-flex px-2 py-3 bg-light rounded baddress">
                                <div className="">
                                    <img src="/images/cam_version_3/file_green.svg" width="23px" />
                                </div>
                                <div className="bg-light w-75">
                                    <p className="py-1 pl-2 smallFont">City</p>
                                    <p className="pl-2 boldFont">{camData && camData.summary.business_address.city}</p>
                                </div>
                            </div>
                        </Col>
                        <Col md="3" className="pl-1">
                            <div className="d-flex  px-2 py-3 bg-light rounded baddress">
                                <div className="">
                                    <img src="/images/cam_version_3/file_green.svg" width="23px" />
                                </div>
                                <div className="bg-light w-75">
                                    <p className="py-1 pl-2 smallFont">State</p>
                                    <p className="pl-2 boldFont ">{camData && camData.summary.business_address.state}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                
            </Card>
            }
        </Col>
    )
}
export default Proposal;
